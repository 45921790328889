@import '../vendors/bootstrap/functions';
@import '../variables';
@import '../vendors/bootstrap/mixins';

.page-main {
  max-width: 1366px;
  padding: 0;
}

.page-title-wrapper {

  @include visually-hidden();
}

.au-home-top-container {
  display: flex;

  .pagebuilder-column-line {

    @include media-breakpoint-between(md, lg) {
      flex-wrap: nowrap;
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }

  .pagebuilder-column {
    align-self: center !important;
  }
}

.au-home-left-container {
  display: flex;
  padding: 5.5rem;
  line-height: 1;

  .heading {
    color: $primary-dark;
    font-size: 5.5rem;
    letter-spacing: -0.25rem;
  }

  .content {
    font-size: 3rem;
    line-height: 1.3;
    letter-spacing: -1.2px;
    padding-right: 6rem;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.7rem;
    font-size: 1.6rem;
    font-weight: 700;
    background-color: $primary;
    border-radius: 50rem;
    height: 5rem;
    width: 24rem;

    &:hover {
      background-color: $primary-dark;
      cursor: pointer;
    }

    [data-element='link'] {
      background-color: transparent;

      &:hover,
      &:visited {
        text-decoration: none;
        background-color: transparent;
      }
    }

    [data-element='link_text'] {
      color: $white;
    }
  }

  //(min-width: 1024px) and (max-width: 1365.98px)
  @include media-breakpoint-between(lg, xl) {
    scale: 90%;
    padding: 1rem 2.5rem;

    .heading {
      font-size: 4.5rem;
    }

    .content {
      padding-right: 0;
    }
  }

  //(min-width: 768px) and (max-width: 1023.98px)
  @include media-breakpoint-between(md, lg) {
    padding-top: 1rem;

    .heading {
      font-size: 3.5rem;
      margin-bottom: 1.5rem;
      line-height: 1;
    }

    .content {
      font-size: 1.8rem;
      padding-right: 0;
    }

    .action {
      margin-top: 0;
      font-size: 1.1rem;
      height: 3.1rem;
      width: 19rem;
    }
  }

  @include media-breakpoint-down(md) {
    order: 2;
    align-items: center;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .heading {
      font-size: 3.5rem;
      line-height: 1;
    }

    .content {
      font-size: 1.8rem;
      padding-right: 0;
    }

    .action {
      font-size: 1.1rem;
      margin-top: 0;
      width: 20rem;
      height: 3rem;
    }
  }
}

.home-images.home-images.home-images {

  @include media-breakpoint-up(md) {
    margin-bottom: 2.9rem;
  }

  figure {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }
}

.home-category,
.home-branding,
.home-brands {
  position: relative;
  z-index: 0;

  h2 {
    font-weight: 400;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: $h2-font-size;
    }
  }

  .btn {
    position: absolute;
    top: 0;
    right: 1rem;
  }
}

.home-category {

  @include media-breakpoint-down(md) {
    background-color: $light;

    h2 {
      margin-top: 3.4rem;
    }
  }

  .cat-name.cat-name a,
  .pagebuilder-overlay.pagebuilder-overlay a {
    font-size: 2.2rem;
  }
}

.home-branding.home-branding.home-branding {
  margin-bottom: 8.4rem;

  @include media-breakpoint-down(md) {
    margin-bottom: 4.8rem;
    padding-bottom: 4.1rem;
  }

  h2 {
    margin-bottom: 4.5rem;

    @include media-breakpoint-down(md) {
      margin-top: 4rem;
      font-weight: 700;
      font-size: $h4-font-size;
    }
  }

  .pagebuilder-column-group {

    @include media-breakpoint-up(lg) {
      margin: 0 -0.8rem;
    }
  }

  .pagebuilder-column {
    position: relative;
    z-index: 0;
    margin: 0 0.8rem;
    border: 1px solid $light;
    transition: $transition-base;

    @include media-breakpoint-down(md) {
      flex-basis: calc(50% - 1.6rem);
      margin-bottom: 1.7rem;
    }

    &:hover {
      box-shadow: 0 0 10px rgba($black, 0.06);
    }

    p {
      margin-bottom: 0;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3.2rem 2rem;
      color: $body-color;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.3;

      @include media-breakpoint-down(md) {
        align-items: flex-start;
        font-size: 1.5rem;
        padding: 1.6rem 1.7rem;
      }

      &:hover {
        text-decoration: none;

        strong,
        &::after {
          transform: translateY(-10px);
        }
      }

      strong {
        transition: transform 0.3s ease-out;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='m16.297 7.794-1.414 1.414 6.293 6.293H7.59v2h13.586l-6.293 6.293 1.414 1.414 8.707-8.707z'/%3E%3C/svg%3E");
        background-size: 24px 24px;
        background-repeat: no-repeat;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-top: 1px;
        transition: transform 0.3s ease-out;
      }
    }
  }

  figure {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }

  .btn {

    @include media-breakpoint-down(md) {
      top: auto;
      bottom: 0;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }
}

.home-brands.home-brands.home-brands {

  @include media-breakpoint-down(md) {
    padding-bottom: 4.2rem;
  }

  h2 {
    margin-bottom: 5.5rem;
    font-weight: 700;
    font-size: $h3-font-size;

    @include media-breakpoint-down(md) {
      margin-top: 0;
      margin-bottom: 4.2rem;
      font-size: $h4-font-size;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    max-width: 1140px;
    margin: 0 auto 6rem;
    padding-left: 0;

    @include media-breakpoint-only(xs) {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  li {
    margin: 0 1rem 1.3rem 1rem;
    padding: 0.1rem;

    @include media-breakpoint-only(xs) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .btn {

    @include media-breakpoint-down(md) {
      top: auto;
      bottom: 5rem;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }
}
